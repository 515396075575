import React from "react";

function Header() {
  return (
    <nav className="bg-gray-800">
    </nav>
  );
}

export default Header;
